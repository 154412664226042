
import { getAdverseDetails, getInternetDetails, getJudesDetails, getNewsDetails, getOffshoreDetails, getPlantifData, getjudyScrapedData, getjurisdictionList, insertGraphNodes, savejudyData } from '../../services';
import Offshore from "./offshore-details/offshore.vue";
import Adverse from "./adverse-details/adverse.vue";
import News from "./news-details/news";
import Internet from "./internet-details/internet";
import CourtRecords from "./court-details/court.vue";
import DandB from "./dnb-details";
import CoorporateRecord from "../../components/companyDetails"
import { getCompanyDetails, getOwnershiDetails  } from '../../pages/search-records/services';
import {saveToolGraphData} from '../../services'
import Loader from "@/components/loader";

export default {
    name: "Details",
    components: {
        Offshore,
        Adverse,
        News,
        Internet,
        CoorporateRecord,
        CourtRecords,
        Loader,
        DandB,
    },
    props: {
        sourceName: String,
        individualData: Object,
        confidence: Boolean,
    },
    data() {
        return {
            detailsData: [],
            companyDetails: null,
            companyDetailsData: null,
            jurisdictionData: null,
            ownershipData: null,
            docketData:[],
            partyDetails: [],
            judgyDetails: [],
            loader : true,
        };
    },
    computed: {
       searchedQuery(){
        return this.$route.query
       }
    },
    methods: {
        async getCompanyData() {
            const payload = {
                card_type:this.individualData?.entity_meta_data.card_type?this.individualData?.entity_meta_data.card_type:"company",
                code:  this.individualData?.entity_meta_data.code,
                doc_id: this.individualData?.entity_meta_data.doc_id,
                number:  this.individualData?.entity_meta_data.company_number,
                query_id:  this.individualData?.entity_meta_data.query_id,
                officer_id:this.individualData?.entity_meta_data.officer_id
            }
            let case_id=this.$route.query.case_id
            const companyDetails = await getCompanyDetails(payload,case_id)
            
            let juriConvert = {}
            this.jurisdictionData.forEach((i)=> ( juriConvert[i.value] =  i.label ))
            this.companyDetails = companyDetails?.data?.data;
            
            let graph = {
                "tool_data": [
                    {
                        "detail": {
                            "company": {
                                ...this.companyDetails?.company
                            }
                        },
                        "company": {
                            "company_number": this.companyDetails?.company?.company_number,
                            "name": this.companyDetails?.company?.name,
                            "incorporation_date": this.companyDetails?.company?.incorporation_date,
                            "current_status": this.companyDetails?.company?.current_status,
                            "jurisdiction_code": this.companyDetails?.company?.jurisdiction_code,
                            "registered_address_in_full": this.companyDetails?.company?.registered_address_in_full
                        },
                        "card_name": this.companyDetails?.company?.name,
                        
                    }
                ],
                "extra_fields": {
                    "search_string": '',
                    "query_data": {},
                    "source": "opencorporates",
                    "jurisdiction": juriConvert
                },
                "case_id": this.searchedQuery.case_id
             } 
              saveToolGraphData(graph);
        },
        async getjurisdictionList() {
            const result = await getjurisdictionList();
            this.jurisdictionData = result.data.data;
        },
        async getownershipData() {
            const newPayload = { comp_id: this.individualData?.entity_meta_data.company_number };
            let case_id=this.$route.query.case_id
            let owner = await getOwnershiDetails(newPayload,case_id);
            this.ownershipData = owner.data.entity
        },
        async handleAdverse() {
            this.loader = false;
            let payload = {
                case_id: this.$route.query.case_id,
                doc_id: this.individualData?.main_entities[0].doc_id
            }
            let data = await getAdverseDetails(payload);
            this.detailsData = data.data.details;
            this.loader = true;
        },
        async handleOffshore() {
            this.loader = false;
            let payload = {
                case_id: this.$route.query.case_id,
                doc_id: this.individualData?.main_entities[0].doc_id
            }
            let data = await getOffshoreDetails(payload);
            this.detailsData = data.data;
            let name =  this.detailsData.connection.map(item => item.doc.name)
            let uniqueArr = [this.detailsData?.data?.name, ...new Set(name)];
            let graphPayload = { edges:[], nodes:[]}
            uniqueArr.forEach(item => {
                graphPayload.edges.push({from: `offshore_leaks_graph___${item}`, to: `offshoreleaks-tool__${item}`, title: "OffshoreLeaks"});
                graphPayload.nodes.push({id: `offshore_leaks_graph___${item}` ,label : `${item}` ,source: ["offshoreleaks"] ,type:"Company Name"})
            })
            graphPayload.nodes.push({
                borderWidth : 2 ,
                color : {border: "#4220FB", font: "#fff", background: "#6E6EFD"},
                id: `offshoreleaks-tool__${this.detailsData?.data?.name}`,
                isQueryNode: true,
                label:`${this.detailsData?.data?.name}`,
                margin: 20,
                shape: "box" ,
                source: ["offshoreleaks"],
                type:"Name"
            })
            let case_id = sessionStorage.getItem("neo_case_id");
            await insertGraphNodes({case_id,"nodes" :graphPayload.nodes,"edges":graphPayload.edges});
            this.loader = true;
        },
        async handleNews() {
            this.loader = false;
            let payload = {
                case_id: this.$route.query.case_id,
                doc_id: this.individualData.main_entities[0].doc_id
            }
             let data = await getNewsDetails(payload);
             this.detailsData = data.data.details;
             this.loader = true;
        },
        async handleInternet() {
            this.loader = false;
            let payload = {
                doc_id: this.individualData._id
            }
            let data = await getInternetDetails(payload,this.$route.query.case_id);
            this.detailsData = data.data.data
            this.loader = true;
        },
        async handleUnicourt(){
            this.loader = false;
            if(this.individualData.source_name === 'unicourt')
            {   
                let payload={
                    case_id:this.individualData?.entity_meta_data.case_id,
                    query_id:this.individualData?.entity_meta_data.query_id,
                }
                // let data = await getAttorneyDetails(payload,this.$route.query.case_id)
                // this.detailsData = data.data;
                let docket = await getJudesDetails(payload,this.$route.query.case_id)
                this.docketData = docket.data.docket_entry_array;
                let partydetails = await getPlantifData(payload,this.$route.query.case_id)
                this.partyDetails = partydetails.data.party_array;
                payload = {
                    case_id: this.individualData?.case_id,
                    details_data: [ { doc_id: this.individualData?._id }]
               }
                await savejudyData(payload);
                this.loader = true;
            }
            else{
               
                this.docketData = this.individualData?.entity_meta_data.docket_entries  
                 let  payload = {
                     case_id: this.individualData?.case_id,
                     details_data: [ { doc_id: this.individualData?._id }]
                }
                    await savejudyData(payload)
                    this.partyDetails = this.individualData?.entity_meta_data['parties'];
                if(!this.individualData?.entity_meta_data.is_details_scraped){
                    let judgypayload = { 
                        case_id: this.individualData?.case_id,
                        detail_id : this.individualData?.entity_meta_data.detail_id
                    }    
                    let judgyDetails =  await getjudyScrapedData(judgypayload);
                    this.judgyDetails =  judgyDetails.data.data
                }   
                this.loader = true;
            }
        }

    },
    async mounted() {
        switch (this.sourceName) {
            case "opencorporates":
               await this.getjurisdictionList();
               this.getCompanyData();
               this.getownershipData();
                break;
            case "complyadvantage":
                this.handleAdverse();
                break;
            case "offshoreleaks":
                this.handleOffshore();
                break;
            case "judyrecords":
                this.handleUnicourt();
                break;    
            case "news":
                this.handleNews();
                break;
            case "internet":
                this.handleInternet()
                break;

        }

    },
    
};
