<template>
  <div>
    <div class="navbar_parent ">
      <ul id="main-entity-ul" class="flex  text-sm font-medium text-center text-gray-500 divide-x divide-gray-200  sm:flex h-9">
           <div @blur="handleBlur" tabindex="1">
               <div v-if="addButton" class="absolute top-1 left-2 z-50 flex justify-between rounded-lg items-center shadow-lg px-4 py-2 w-60 bg-white">
             <div class="flex justify-center items-center cursor-pointer" @click="handelPerson('personModal')">
               <UserIcon :fill="mainColor"/><span class="mx-1">Person</span>
             </div>
             <div class="border h-6"></div>
             <div class="flex justify-center items-center cursor-pointer" @click="handelPerson('companyModal')">
               <CompanyIcon :fill="mainColor"/><span class="mx-2">Company</span>
             </div>
           </div>
           <div class="flex items-center justify-center h-9 dynamic_color px-3 rounded-l-lg cursor-pointer" @click="toggleAddButton">
             <plus :fill="`#ffffff`"/>
           </div>
        </div>
        <li v-for="(item, index) in mainCasedata" :key="index" class="w-full  shadow"  >
          <div class="flex justify-between items-center px-2 py-1 h-9 text-gray-900 bg-white hover:bg-red-10 w-42"
            :class="item.checked ? 'bg-red-100' : ''">
            <span class="w-7 h-7  rounded-full bg-red-200 flex justify-center items-center"
              v-if="item?.categories_type === 'officers'">
              <UserIcon  :fill="mainColor"/>
            </span>
            <span class="w-7 h-7  rounded-full bg-red-200 flex justify-center items-center" v-else>
              <CompanyIcon :fill="mainColor" />
            </span>
            <label :title="item?.entity" :for="`userid_${index}`"> {{ item?.entity.length > 5 ? `${item.entity.substr(0,
              5)}...` : item?.entity }}</label>
            <span class="text-left">
              <CanvasMenu :individualData="item" :entityHead="true" :mainEntity="caseData" :isMainenity="true" @openPersonModal="handelPerson" :editAble="true" />
            </span>
            <span class="mx-1 cursor-pointer" v-if="!item.run_intelligence">
              <DeleteIcon :fill="mainColor" @click="deleteEntity(item)" />
            </span>
            <input v-if="item.run_intelligence" type="checkbox" v-model="item.checked" @change="handleCheckboxChange(item, index)"
              :id="`userid_${index}`" />
          </div>
        </li>
        <li class="w-full dropdown_parent">
          <button @click="toggleList" v-show="multiData.length > 0" ref="dropdown"
            class="w-full text-red-800 bg-white  flex justify-around items-center h-9">
            <div class="flex relative ">
              <span class="z-0  w-7 h-7 border-2 border-white rounded-full bg-red-200 flex justify-center items-center">
                <UserIcon :fill="mainColor"/>
              </span>
              <span
                class="absolute border-2 border-white left-5 z-10 w-7 h-7 p-1 rounded-full bg-red-200 flex justify-center items-center">
                <CompanyIcon :fill="mainColor" />
              </span>
            </div>
            + &nbsp;{{ multiData.length }} More
            <font-awesome-icon :icon="['fas', 'chevron-down']" color="black" size="xs" />
          </button>
          <ul v-if="showList"
            class="dropdown_content  text-sm font-medium text-center text-gray-500 divide-y divide-gray-200  border border-gray-200 shadow  h-auto">
            <li v-for="(item, index) in multiData" :key="index" class="w-42 h-9">
              <div
                class="flex justify-between items-center w-full px-4 py-1 text-gray-900 bg-white hover:bg-red-100 position-relative"
                :class="item.checked ? 'bg-red-100' : ''">
                <span class="w-7 h-7 rounded-full bg-red-200 flex justify-center items-center"
                  v-if="item.categories_type === 'officers'">
                  <UserIcon :fill="mainColor"/>
                </span>
                <span class="w-7 h-7  rounded-full bg-red-200 flex justify-center items-center" v-else>
                  <CompanyIcon :fill="mainColor"/>
                </span>
                <span :title="item.name" :for="`userid_${index}`"> {{ item.name.length > 5 ? `${item.name.substr(0,
                  5)}...` : item.name }}</span>
                <span class="text-left ">
                  <CanvasMenu :individualData="item" :entityHead="true" :mainEntity="caseData" :isMainenity="true"  :confidence="false" @openPersonModal="handelPerson" />
                </span>
                <span class="mx-1 cursor-pointer" v-if="!item.run_intelligence">
              <DeleteIcon :fill="mainColor" @click="deleteEntity(item)" />
            </span>
                <input v-if="item.run_intelligence" type="checkbox" id="dropdown" v-model="item.checked" @change="handleCheckboxChange(item, index)" />
              </div>
            </li>
          </ul>
        </li>
      </ul>
  
    </div>
  </div>
</template>
<script>

import UserIcon from "../../assets/svg/user.svg";
import CompanyIcon from "../../assets/svg/company.svg";
import CanvasMenu from "../../components/canvas-menu"
import plus from '../../assets/svg/plus.svg';
import DeleteIcon from "../../assets/svg/deleteIcon.svg";

export default {
  components: {
    plus,
    UserIcon,
    CompanyIcon,
    CanvasMenu,
    DeleteIcon,
  },
  props: ['caseData','addbutton'],
  data() {
    return {
      showList: false,
      isCanvas: false,
      mainColor: '',
      addButton: false,
    }
  },
  methods: {
    handleCheckboxChange(item, index) {
      this.$emit('caseData-checked', item, index);
    },
    toggleList() {
      this.showList = !this.showList;
    },
    handleOutsideClick(event) {
      if (this.isCanvas == true) { return }
      if (!this.$refs.dropdown.contains(event.target) && (!(event.target.id == 'dropdown'))) {
        if (event.target.id == 'isCanvas') { this.isCanvas = true }
        this.showList = false;
      }
    },
    handelPerson(type, data){
      this.$emit('handelPerson',type,data);
      this.addButton = false;
    },
    handleBlur() {
     this.addButton = false;
    },
    toggleAddButton() {
      this.addButton = !this.addButton;
    },
    deleteEntity(item) {
      this.$emit("deleteEntity", item._id);
    },
  },
  watch: {

  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  computed: {
    mainCasedata() {
      if (this.caseData.length < 5) {
        return this.caseData;  //0,1,2,3,4
      }
      else {
        return this.caseData.filter((ele, index) => (index <= 4))  //0,1,2,3,4
      }
    },
    multiData() {
      return this.caseData.filter((ele, index) => (index > 4))
    },
  },
  created() {
        const rootStyles = getComputedStyle(document.documentElement);
        this.mainColor = rootStyles.getPropertyValue("--theme-color-main");
      },
     
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/color.scss";
.navbar_parent {
  ul#main-entity-ul:hover{
    background-color: transparent !important;
  }
  li {
    cursor: pointer !important;
    max-width: 220px;
  }
}

.dropdown_parent {
  position: relative !important;
  
}

.dynamic_color{
    background: $lightray_product_color;
}

.dropdown_content {
  position: absolute !important;
  top: 100% !important;
  left: 0%;
  z-index: 1000 !important;
  width: inherit;
}
</style>